<template>
    <settings-wrapper>
        <template v-if="isLoading"><Spinner /></template>
        <template v-slot:child>
            <div class="scrollmenu w-90 center main-bg">
                <div class="box-border mt4">
                    <div class="box-border-bottom pa3 f4 b">Integrations</div>
                    <div class="pl3 pt3 font-w1">Payment</div>
                    <div class="flex flex-wrap pa3">
                        <!-- <div class="box-border pa3 w-30-l w-100 mr3-l mb0-l mb3">
                            <div class="flex items-center">
                                <img :src="require('@/assets/images/stripe.svg')" alt="">
                                <div class="pl2 b">Stripe</div>
                            </div>
                        </div> -->
                        <div class="box-border pa3 w-30-l w-100 mr3-l  mb0-l mb3">
                            <router-link :to="{ name: 'PaystackIntegration' }" class="w-100">
                                <div>
                                    <img :src="require('@/assets/images/paystack.svg')" alt="">
                                </div>
                            </router-link>
                            <div v-if="state.isPaystackConnected" class="flex justify-between items-center mt2">
                                <router-link :to="{ name: 'PaystackWebhookDetails' }">
                                    View webhook detail
                                </router-link>
                                <div v-if="state.disconnectingPaystack" class="text-muted">Disconnecting...</div>
                                <a v-else href="#" 
                                    @click="disconnectPaystackIntegration"
                                >Disconnect</a>
                            </div>
                        </div>
                        <!-- <div class="box-border pa3 w-30-l w-100 mr3-l  mb0-l mb3">
                            <div>
                                <img :src="require('@/assets/images/flutterwave.svg')" alt="">
                            </div>
                        </div> -->
                    </div>
                    <!-- Social media -->
                    <!-- <div class="pa3 font-w1">Social Media</div>
                    <div class="flex flex-wrap pl3">
                        <div class="box-border pa3 w-30-l w-100 mr3-l  mb0-l mb3">
                            <div class="flex items-center">
                                <img :src="require('@/assets/images/whatsapp.svg')" alt="">
                                <div class="pl2 b">Whatsapp</div>
                            </div>
                        </div>
                        <div class="box-border pa3 w-30-l w-100 mr3-l  mb0-l mb3">
                            <div class="flex items-center">
                                <img :src="require('@/assets/images/instagram.svg')" alt="">
                                <div class="pl2 b">Instagram</div>
                            </div>
                        </div>
                    </div> -->
                    <!-- website -->
                    <!-- <div class="pa3 font-w1">Website</div>
                    <div class="flex flex-wrap pl3">
                        <div class="box-border pa3 w-30-l w-100 mr3-l mb3">
                            <div class="flex items-center">
                                <img :src="require('@/assets/images/WooCommerce.svg')" alt="">
                                <div class="pl2 b">Woo Commerce</div>
                            </div>
                        </div>
                        <div class="box-border pa3 w-30-l w-100 mr3-l mb3">
                            <div class="flex items-center">
                                <img :src="require('@/assets/images/bigcomm.svg')" alt="">
                                <div class="pl2 b">Big Commenrce</div>
                            </div>
                        </div>
                        <div class="box-border pa3 w-30-l w-100 mr3-l mb3">
                            <div class="flex items-center">
                                <img :src="require('@/assets/images/Wix.svg')" alt="">
                                <div class="pl2 b">Wix</div>
                            </div>
                        </div>
                        <div class="box-border pa3 w-30-l w-100 mr3-l mb3">
                            <div class="flex items-center">
                                <img :src="require('@/assets/images/Shopify.svg')" alt="">
                                <div class="pl2 b">Shopify</div>
                            </div>
                        </div>
                        <div class="box-border pa3 w-30-l w-100 mr3-l mb3">
                            <div class="flex items-center">
                                <img :src="require('@/assets/images/magento.svg')" alt="">
                                <div class="pl2 b">Magento</div>
                            </div>
                        </div>
                    </div> -->
                    <!-- ecommenrce -->
                    <!-- <div class="pa3 font-w1 ">E-commenrce</div>
                    <div class="flex flex-wrap pl3">
                        <div class="box-border pa3 w-30-l w-100 mr3-l mb3">
                            <div class="flex items-center">
                                <img :src="require('@/assets/images/jumia.svg')" alt="">
                                <div class="pl2 b">Jumia</div>
                            </div>
                        </div>
                        <div class="box-border pa3 w-30-l w-100 mr3-l  mb3">
                            <div class="flex items-center">
                                
                                <div class="pl2 b">KONGA</div>
                            </div>
                        </div>
                        <div class="box-border pa3 w-30-l w-100 mr3-l mb3">
                            <div class="flex items-center">
                                <img :src="require('@/assets/images/Amazon.svg')" alt="">
                                <div class="pl2 b">Amazon</div>
                            </div>
                        </div>
                        <div class="box-border pa3 w-30-l w-100 mr3-l mb3">
                            <div class="flex items-center">
                                <img :src="require('@/assets/images/selar.svg')" alt="">
                                <div class="pl2 b">Selar</div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </template>
    </settings-wrapper>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'

export default {
    name: 'PaystackIndex',
    components: { SettingsWrapper },

    setup() {
        const store = useStore()

        const initaialState = {
            isPaystackConnected: false,
            disconnectingPaystack: false
        }

        const state = reactive(initaialState)

        const disconnectPaystackIntegration = async () => {
            state.disconnectingPaystack = true
            store.dispatch('Settings/disconnectPaystackIntegration').then(response => {
                if (response?.status) {
                    state.isPaystackConnected = false
                }
            }).finally(() => state.disconnectingPaystack = false)
        }

        onMounted(() => {
            store.dispatch('Settings/getIntegrationSettings').then(response => {
                if (response?.status && response?.data?.paystack?.secretKey) {
                    state.isPaystackConnected = true
                }
            })
        })

        return {
            state,
            disconnectPaystackIntegration
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 1020px) {
    .box-container {
        display: flex;
        padding: 1rem 0px;
        margin: 10px;
        flex-direction: column;
        white-space: normal !important;
    }
}

.buttom-billings {
    margin-top: 10px;
}
.lh-copy {
    background-color: #fff;
    padding: 0px 20px;
}
.p9 {
    padding: 10px;
}
.bg-white {
    background-color: #e8ecf5;
}
.bb {
    border-bottom: 1px solid rgb(233, 230, 230);
    border-bottom-width: 1px;
}
.Upcoming-Invoice-table {
    padding: 0px 10px;
}
.bg-container-2 {
    background: rgb(251, 252, 254);
}

.Upcoming-Invoice-1 {
    background-color: #fff;
    margin: 0px 10px;
    padding: 5px 10px;
}
.Upcoming-Invoice {
    background-color: #fff;
    margin: 10px;
    border: 1px solid #e3e8ee;
    padding: 5px 10px;
}

.billing-box-1 {
    width: 55%;
    height: 150px;
    background: rgb(251, 252, 254);
    margin-right: 20px;
}
.billing-box-2 {
    width: 100%;
    height: 100px;
    background-color: white;
    border: 1px solid #e3e8ee;
    padding: 10px;
}

.box-container {
    display: flex;
    padding: 1rem 0px;
    margin: 10px;
}
.main-bg {
    background: rgb(251, 252, 254);
}

.inner-box1 {
    width: 100%;
    height: 80px;
    background-color: white;
    border: 1px solid #e3e8ee;
}
.inner-box2 {
    width: 100%;
    height: 50px;
    background-color: white;
    bottom: 1px;
    margin-top: 1rem;
    border: 1px solid #e3e8ee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}

.inner-text-contain {
    padding: 10px !important;
}
.inner-text-contain1 {
    padding: 0px 10px;
    padding-bottom: 10px;
}
.inner-text-color {
    background-color: rgb(19, 44, 140);
    color: #fff;
    font-weight: 500;
    padding: 3px 5px;
}
.inactive-text-color {
    background-color: red;
    color: #fff;
    padding: 3px 5px;
}
.active-text-color {
    background-color: #2ef23b;
    color: black;
    padding: 3px 5px;
}

.makerounded-md {
    border-radius: 0.375rem /* 6px */;
}

.griddesign {
    border: 1px solid #e3e8ee;
    width: 100%;
    padding: 10px;
}

.pagebutton {
    padding: 4px 6px;
    font-weight: 500;
}

.payment {
    background: #aab9f2;
    font-weight: 700;
    color: #132c8c;
    width: 10rem;
    border-radius: 0.375rem /* 6px */;
    text-align: center;
    padding: 10px;
}
</style>
